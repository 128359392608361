// Order is important (from most specific to least specific, e.g. Pale Orange before Orange)

const longColours = {
  Metal: "M",
  Uncoloured: "M",
  "Pale Orange": "PO",
  "Neon Orange": "NO",
  "Fluoro Orange": "NO",
  "Pale Pink": "PP",
  "Neon Pink": "NP",
  "Fluoro Pink": "NP",
  "Hot Pink": "NP",
  "Dark Pink": "CP",
  "Pale Blue": "PB",
  "Light Blue": "LB",
  "Medium Blue": "B",
  "Dark Blue": "DB",
  "Neon Green": "NG",
  "Fluoro Green": "NG",
  "Light Green": "LG",
  "Lime Green": "LG",
  "Pale Green": "PG",
  "Medium Green": "G",
  "Dark Green": "DG",
  "Light Purple": "LPu",
  Black: "K",
  Grey: "Gr",
  White: "W",
  Red: "R",
  Orange: "O",
  Yellow: "Y",
  Pink: "P",
  Crimson: "CP",
  Purple: "Pu",
  Blue: "B",
  Green: "G",
  Brown: "Br",
};

const longCombinedColours = {
  metal: "M",
  uncoloured: "M",
  paleorange: "PO",
  neonorange: "NO",
  fluoroorange: "NO",
  palepink: "PP",
  neonpink: "NP",
  fluoropink: "NP",
  hotpink: "NP",
  crimson: "CP",
  darkpink: "CP",
  lightpurple: "LPu",
  paleblue: "PB",
  lightblue: "LB",
  mediumblue: "B",
  darkblue: "DB",
  neongreen: "NG",
  fluorogreen: "NG",
  lightgreen: "LG",
  limegreen: "LG",
  palegreen: "PG",
  mediumgreen: "G",
  darkgreen: "DG",
  black: "K",
  grey: "Gr",
  white: "W",
  red: "R",
  orange: "O",
  yellow: "Y",
  pink: "P",
  purple: "Pu",
  blue: "B",
  green: "G",
  brown: "Br",
};

const shortColours = {
  M: "Metal",
  PO: "Pale Orange",
  NO: "Neon Orange",
  PP: "Pale Pink",
  NP: "Neon Pink",
  LPu: "Light Purple",
  PB: "Pale Blue",
  LB: "Light Blue",
  B: "Medium Blue",
  DB: "Dark Blue",
  NG: "Neon Green",
  LG: "Light Green",
  L: "Light Green",
  PG: "Pale Green",
  G: "Medium Green",
  DG: "Dark Green",
  K: "Black",
  Gr: "Grey",
  W: "White",
  R: "Red",
  O: "Orange",
  Y: "Yellow",
  P: "Pink",
  CP: "Crimson",
  Pu: "Purple",
  Br: "Brown",
};

export { longColours, longCombinedColours, shortColours };
