const colourLibrary = {
  M: {
    label: "Metal",
    value: "linear-gradient(to right,#808080, #ddd, #808080)",
  },
  PO: {
    label: "Pale Orange",
    value: "#FFD03B",
  },
  NO: {
    label: "Neon Orange",
    value: "#FFB343",
  },
  PP: {
    label: "Pale Pink",
    value: "#FBC6B7",
  },
  NP: {
    label: "Neon Pink",
    value: "#FF4BF2",
  },
  LPu: {
    label: "Light Purple",
    value: "#AE5DFF",
  },
  PB: {
    label: "Pale Blue",
    value: "#9EC7F8",
  },
  LB: {
    label: "Light Blue",
    value: "#2B8FB3",
  },
  B: {
    label: "Medium Blue",
    value: "#0060EE",
  },
  DB: {
    label: "Dark Blue",
    value: "#0000D6",
  },
  NG: {
    label: "Neon Green",
    value: "#00FF00",
  },
  LG: {
    label: "Light Green",
    value: "#ABFE82",
  },
  L: {
    label: "Light Green",
    value: "#ABFE82",
  },
  PG: {
    label: "Pale Green",
    value: "#4DC350",
  },
  G: {
    label: "Medium Green",
    value: "#00A800",
  },
  DG: {
    label: "Dark Green",
    value: "#003A00",
  },
  K: {
    label: "Black",
    value: "#000000",
  },
  Gr: {
    label: "Grey",
    value: "#808080",
  },
  W: {
    label: "White",
    value: "#ffffff",
  },
  R: {
    label: "Red",
    value: "#DA0000",
  },
  O: {
    label: "Orange",
    value: "#FF6600",
  },
  Y: {
    label: "Yellow",
    value: "#F4EE00",
  },
  P: {
    label: "Pink",
    value: "#FE94FE",
  },
  CP: {
    label: "Crimson",
    value: "#CC044B",
  },
  Pu: {
    label: "Purple",
    value: "#9900CC",
  },
  Br: {
    label: "Brown",
    value: "#996633",
  },
};

export default colourLibrary;
